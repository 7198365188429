$(document).ready(function () {
    //Search Bar
    const searchButton = document.getElementById("navi__searchbutton");
    const searchBar = document.getElementById("navi__searchbar");

    const langswitchButton = document.getElementById("lang-switch");
    const langswitchBar = document.getElementById("navi__lang-switch");
    
    langswitchButton.addEventListener("click", event => {
        searchBar.classList.add("navi__searchbar--hidden");
        langswitchBar.classList.toggle("navi__lang-switch--hidden");
    })

    searchButton.addEventListener("click", event => {
        langswitchBar.classList.add("navi__lang-switch--hidden");
        searchBar.classList.toggle("navi__searchbar--hidden");
    })
})

$('.navi__lang').click(function (event) {
    console.log(this);
})