$(document).ready(function () {
    //Mobile Navi
    const naviButton = document.getElementById("navi__button");
    const naviList = document.getElementById("navi__linklist");
    const naviOverlay = document.getElementById("navi__overlay");
    naviButton.addEventListener("click", event => {
        if (naviButton.classList.contains("navi__button--close")) {
            //close navi
            naviButton.classList.remove("navi__button--close");
            naviList.classList.remove("navi__linklist--open");
            naviOverlay.classList.remove("navi__overlay--visible");
        } else {
            //open navi
            naviButton.classList.add("navi__button--close");
            naviList.classList.add("navi__linklist--open");
            naviOverlay.classList.add("navi__overlay--visible");
        }
    })
    //Mobile Navi End  
})