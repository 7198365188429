
$(document).ready(function () {
    $('#logostripe').slick({
        slidesToShow: 8,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        speed: 8000,
        pauseOnHover: false,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 6,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 4,
                }
            }
        ]
    });

    $('.endless-slider').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        speed: 14000,
        pauseOnHover: false,
        cssEase: 'linear',
        infinite: true,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 1,
                    speed: 13000,
                }
            }
        ]
    });
})