$(document).ready(function () {
	$(".lightbox-open").on("click", function () {
		$("body").addClass("no-body-scrolling");
		$(this).parent().find(".lightbox").addClass("active");

    if ($(this).parent().find("iframe")) {
      let link = $(this).parent().find("iframe").data("yt-link")
      $(this).parent().find("iframe").attr("src", link);
    }
	});
  
  $(".lightbox-close").on("click", function() {
    $("body").removeClass("no-body-scrolling");
		$(".lightbox").removeClass("active");
    if ($(this).parent().find("iframe")) {
			$(this).parent().find("iframe").attr("src", "");
		}
  })
});
