let animationHasFired = false;

$(document).ready(function () {
    // init controller
    var controller = new ScrollMagic.Controller(); //ScrollMagic initialisieren

    // add a scene
    new ScrollMagic.Scene({
		triggerElement: '#facts',
		duration: 2,
		triggerHook: 1.5
	})
    .on('enter', function(e){
        countUp();
    })
	.addTo(controller)



    function countUp() {
        const counters = document.querySelectorAll('.counter');

        for (let n of counters) {
            const updateCount = () => {
                const target = + n.getAttribute('data-target');
                const count = + n.innerText;
                const speed = n.getAttribute('data-speed');

                const inc = target / speed;

                if (count < target) {
                    n.innerText = Math.ceil(count + inc);
                    setTimeout(updateCount, 30);
                } else {
                    n.innerText = target;
                }
            }

            updateCount();
        }
        // DOM Element's
        const slowcounters = document.querySelectorAll('.slow-counter');


        /*** Same functionality, now using for...of ***/

        for (let n of slowcounters) {
            const updateSlowCount = () => {
                const target = + n.getAttribute('data-target');
                const count = + n.innerText;
                const speed = n.getAttribute('data-speed');

                const inc = target / speed;

                if (count < target) {
                    n.innerText = Math.ceil(count + inc);
                    setTimeout(updateSlowCount, 70);
                } else {
                    n.innerText = target;
                }
            }

            updateSlowCount();
        }
        const slowercounters = document.querySelectorAll('.slower-counter');


        /*** Same functionality, now using for...of ***/

        for (let n of slowercounters) {
            const updateSlowerCount = () => {
                const target = + n.getAttribute('data-target');
                const count = + n.innerText;
                const speed = n.getAttribute('data-speed');

                const inc = target / speed;

                if (count < target) {
                    n.innerText = Math.ceil(count + inc);
                    setTimeout(updateSlowerCount, 130);
                } else {
                    n.innerText = target;
                }
            }

            updateSlowerCount();
        }
    }

/* document.addEventListener("scroll", function () {
    console.log
    let rect = document.getElementById("facts").getBoundingClientRect();

    if (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) &&
        !animationHasFired 
    ) {
       
        
  

    };
}); */

})