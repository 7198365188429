
$(document).ready(function () {
    $('.intro-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 800,
        pauseOnHover: false,
        centerMode: true,
        arrows: false,
        dots: true,
        fade: true,
        autoplay: true,
        autoplaySpeed: 10000

    });
})

$(document).ready(function () {
    $('.projects-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 800,
        pauseOnHover: false,
        centerMode: true,
        arrows: false,
        dots: true,
        fade: true,
        autoplay: true,
        autoplaySpeed: 10000

    });
})

$(document).ready(function () {
    $('.galleryslider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        speed: 800,
        pauseOnHover: true,
        centerMode: true,
        arrows: true,
        prevArrow: ".galleryslider__arrow--prev",
        nextArrow: ".galleryslider__arrow--next",
        dots: false,
        fade: true,
        autoplay: true,
        autoplaySpeed: 10000

    });
})